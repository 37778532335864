import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { SidenavItem } from "./sidenav-item/sidenav-item.model";
import { Observable } from "rxjs";
import * as fromRoot from "../../reducers/index";
import { Store } from "@ngrx/store";
import { AuthService } from "../../auth.service";

@Component({
  selector: "vr-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input("layout") layout: string; // Layout
  @Input("collapsed") collapsed: boolean;
  @Output("toggledSidenavCollapse") toggledSidenavCollapse = new EventEmitter();

  sidenavItems$: Observable<SidenavItem[]>;
  currentlyOpen$: Observable<SidenavItem[]>;
  isAuthenticated$: Observable<boolean>;

  constructor(
    private store: Store<fromRoot.State>,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isAuthenticated$.subscribe(value => {
      console.warn("AUTHENTICATED CHANGED: " + value)
    })
  }

  ngOnInit() {
    this.sidenavItems$ = this.store.select(fromRoot.getSidenavItems);
    this.currentlyOpen$ = this.store.select(fromRoot.getSidenavCurrentlyOpen);
  }

  toggleSidenavCollapse() {
    this.toggledSidenavCollapse.emit();
  }

  ngOnDestroy() {}
}
