import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { User } from "../../users/user";

// actions
import { SignOutAction } from "../../users/users.actions";

// reducers
import {
    getAuthenticatedUser,
    isAuthenticated,
    State
} from "../../../reducers";
import { AuthService } from '../../../auth.service';

@Component({
    selector: 'vr-toolbar-user-button',
    templateUrl: './toolbar-user-button.component.html',
    styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

    isOpen: boolean;
    isAuthenticated: Observable<boolean>;
    user: Observable<User>;

    constructor(
        private authService: AuthService, 
        private store: Store<State>, 
        private cd: ChangeDetectorRef) {
    }

    ngOnInit() {

        this.isAuthenticated = this.authService.isAuthenticated$;
        // this.user = this.store.select(getAuthenticatedUser);
        // this.user.subscribe(x => this.cd.markForCheck());

        // this.store.select(isAuthenticated)
        //     .subscribe(auth => {
        //         this.isAuthenticated = auth;
        //         this.cd.markForCheck();
        //     });
    }

    ngAfterViewInit() {
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    onClickOutside() {
        this.isOpen = false;
    }

    handleLogout() {
        this.store.dispatch(new SignOutAction());
    }
}
