import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PagesModule } from "./pages/pages.module";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { reducers } from "./reducers/index";
import { UserEffects } from "./core/users/users.effects";
import { LayoutEffects } from "./core/layout/shared/layout.effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { EffectsModule } from "@ngrx/effects";
import { AgmCoreModule } from "@agm/core";
import { RouteHandlerModule } from "./core/route-handler/route-handler.module";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { UploadModule } from "@progress/kendo-angular-upload";
import { HttpClientModule } from "@angular/common/http";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { GridModule } from "@progress/kendo-angular-grid";
import { LoginButtonComponent } from "./login-button/login-button.component";
import { AppConfigService } from "./app-config.service";

export function initializeApp(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : [],
    EffectsModule.forRoot([UserEffects, LayoutEffects]),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    AppRoutingModule,
    CoreModule,
    PagesModule,
    RouteHandlerModule,
    HttpClientModule,

    // Kendo
    DateInputsModule,
    DropDownsModule,
    GridModule,
    TreeViewModule,
    UploadModule,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  declarations: [AppComponent, LoginButtonComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
