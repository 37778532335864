
import {throwError as observableThrowError,  Observable, of } from 'rxjs';

import {switchMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import "rxjs/add/observable/throw";
import { User, SignInResult, SignOutResult, ClaimTypes } from "./user";
import { AuthHttp } from '../utils/auth.http';
import { environment } from '../../../environments/environment';


/**
 * The user service.
 */
@Injectable()
export class UserService {
    
    constructor(
        private _http: AuthHttp) { }

    /**
     * Authenticate the user
     *
     * @param {string} username The user's email address
     * @param {string} password The user's password
     * @returns {Observable<User>} The authenticated user observable.
     */
    public authenticate(username: string, password: string): Observable<User> {
        
        var authApiUrl = environment.apiBaseUrl + "session/signin"; 
        var that = this;

        return this.httpPost(authApiUrl, {
                userName: username,
                password: password
            }).pipe(
            switchMap((result: SignInResult) => {
                if (result.success) {
                    var nameClaim = result.claims.find(c => c.type == ClaimTypes.DisplayName);
                    // Successful signin
                    var user = new User();
                    if (nameClaim) user.displayName = nameClaim.value;
                    localStorage.setItem("profile", JSON.stringify(user));
                    localStorage.setItem("id_token", result.token);
                    return of(user);
                }
                else {
                    return observableThrowError(new Error(result.message));
                }
            }));
    }

    // /**
    //  * Determines if the user is authenticated
    //  * @returns {Observable<boolean>}
    //  */
    // public authenticated(): Observable<boolean> {
    //     return Observable.of(this._authenticated);
    // }

    // /**
    //  * Returns the authenticated user
    //  * @returns {User}
    //  */
    // public authenticatedUser(): Observable<User> {
    //     // Normally you would do an HTTP request to determine if
    //     // the user has an existing auth session on the server
    //     // but, let's just return the mock user for this example.
    //     return Observable.of(this._user);
    // }

    // /**
    //  * Create a new user
    //  * @returns {User}
    //  */
    // public create(user: User): Observable<User> {
    //     // Normally you would do an HTTP request to POST the user
    //     // details and then return the new user object
    //     // but, let's just return the new user for this example.
    //     this._authenticated = true;
    //     return Observable.of(user);
    // }

    /**
     * End session
     * @returns {Observable<boolean>}
     */
    public signout(): Observable<boolean> {

        var authApiUrl = environment.apiBaseUrl + "session/signout"; 
        var that = this;

        return this.httpPost(authApiUrl, {}).pipe(
            switchMap((result: SignOutResult) => {
                if (result.success) {
                    // Successful signin
                    localStorage.removeItem("profile");
                    localStorage.removeItem("id_token");
                    return of(true);
                }
                else {
                    return of(false);
                }
            }));
    }




    private httpPost(url: string, data: any): Observable<Object> {
        return this._http.post(url, JSON.stringify(data));
    }
}
