import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";

// import rxjs
import { Observable } from "rxjs";

// import @ngrx
import { Store } from "@ngrx/store";

// reducers
import { isAuthenticated, State } from "../../reducers";
import { AuthService } from "../../auth.service";
import { map } from "rxjs/operators";

/**
 * Prevent unauthorized activating and loading of routes
 * @class AuthenticatedGuard
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate {
  /**
   * @constructor
   */
  constructor(private auth: AuthService, private router: Router) {}

  /**
   * True when user is authenticated
   * @method canActivate
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.auth.isAuthenticated$.pipe(
      map((authed) => {
        if (!authed) {
          // this.auth.login();
          // return false;
          return true;
        }
        return true;
      })
    );
  }
}
