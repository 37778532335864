import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  constructor(private http: HttpClient) {}
  async load() {
    const config = await this.http
      .get('./assets/config/ui-appsettings.json')
      .toPromise();
    Object.assign(environment, config);
  }
}
