<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
    <div class="login mat-elevation-z12">
        <div class="login-header" fxLayout="column" fxLayoutAlign="center center">
            <img class="logo" src="assets/img/logo2x.png">
        </div>
        
        <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>

        <form #form="ngForm" (ngSubmit)="login()">
            
            <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
                <mat-form-field>
                    <input matInput placeholder="User Name" name="username" required [(ngModel)]="username">
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Password" type="password" name="password" required [(ngModel)]="password">
                </mat-form-field>
                <div class="extra-options" fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox class="remember-me">Remember Me</mat-checkbox>
                    <!-- <a class="forgot-password" [routerLink]="['/auth/forgot-password']">Forgot Password?</a> -->
                </div>
                <button color="primary" mat-raised-button [disabled]="!form.valid">SIGN IN</button>
                <!-- <p class="note">Don't have an account?<br/> <a [routerLink]="['/auth/register']">Click here to create one</a></p> -->
                <p class="error">{{ error$ | async }}</p> 
            </div>
        </form>
    </div>
</div>