// import actions
import { Actions, ActionTypes } from "./users.actions";

// import models
import { User } from "../../core/users/user";

/**
 * The state.
 * @interface State
 */
export interface State {

    // boolean if user is authenticated
    authenticated: boolean;

    // error message
    error?: string;

    // true if we have attempted existing auth session
    loaded: boolean;

    // true when loading
    loading: boolean;

    // the authenticated user
    user?: User;
}

/**
 * The initial state.
 */
const initialState: State = {
    authenticated: false,
    loaded: false,
    loading: false,
    user: null
};



/**
* The reducer function.
* @function reducer
* @param {State} state Current state
* @param {Actions} action Incoming action
*/
export function reducer(state: State = initialState, action: Actions): State {
    const INITIAL_STATE = "@ngrx/store/init";

    switch (action.type) {
        case INITIAL_STATE:
            var token = localStorage.getItem("id_token");  
            var profile = localStorage.getItem("profile");      
            var newState = Object.assign({}, state, { 
                authenticated: (token ? true : false), 
                user: profile ? <User>JSON.parse(profile) : null,
                loaded: true
            });
            return newState;
        
        case ActionTypes.AUTHENTICATE:
            return Object.assign({}, state, {
                loading: true,
                error: null
            });

        case ActionTypes.AUTHENTICATED_ERROR:
            return Object.assign({}, state, {
                authenticated: false,
                error: action.payload.error.message,
                loaded: true
            });

        case ActionTypes.AUTHENTICATED_SUCCESS:
            return Object.assign({}, state, {
                authenticated: action.payload.authenticated,
                loaded: true,
                user: action.payload.user
            });

        case ActionTypes.AUTHENTICATE_ERROR:
        case ActionTypes.SIGN_UP_ERROR:
            return Object.assign({}, state, {
                authenticated: false,
                error: action.payload.error.message,
                loading: false
            });

        case ActionTypes.AUTHENTICATE_SUCCESS:
        case ActionTypes.SIGN_UP_SUCCESS:
            const user: User = action.payload.user;

            // verify user is not null
            if (user === null) {
                return state;
            }

            return Object.assign({}, state, {
                authenticated: true,
                error: undefined,
                loading: false,
                user: user
            });

        case ActionTypes.SIGN_OUT_ERROR:
            return Object.assign({}, state, {
                authenticated: true,
                error: action.payload.error.message,
                user: undefined
            });

        case ActionTypes.SIGN_OUT_SUCCESS:
            return Object.assign({}, state, {
                authenticated: false,
                error: undefined,
                user: undefined
            });

        case ActionTypes.SIGN_UP:
            return Object.assign({}, state, {
                authenticated: false,
                error: undefined,
                loading: true
            });

        default:
            return state;
    }
}




/**
 * Returns true if the user is authenticated.
 * @function isAuthenticated
 * @param {State} state
 * @returns {boolean}
 */
export const isAuthenticated = (state: State) => state.authenticated;

/**
 * Returns true if the authenticated has loaded.
 * @function isAuthenticatedLoaded
 * @param {State} state
 * @returns {boolean}
 */
export const isAuthenticatedLoaded = (state: State) => state.loaded;

/**
 * Return the users state
 * @function getAuthenticatedUser
 * @param {State} state
 * @returns {User}
 */
export const getAuthenticatedUser = (state: State) => state.user;

/**
 * Returns the authentication error.
 * @function getAuthenticationError
 * @param {State} state
 * @returns {Error}
 */
export const getAuthenticationError = (state: State) => state.error;

/**
 * Returns true if request is in progress.
 * @function isLoading
 * @param {State} state
 * @returns {boolean}
 */
export const isLoading = (state: State) => state.loading;

/**
 * Returns the sign out error.
 * @function getSignOutError
 * @param {State} state
 * @returns {Error}
 */
export const getSignOutError = (state: State) => state.error;

/**
 * Returns the sign up error.
 * @function getSignUpError
 * @param {State} state
 * @returns {Error}
 */
export const getSignUpError = (state: State) => state.error;