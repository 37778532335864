<div class="navigation-item-container" *ngIf="item.hasSubItems()" routerLinkActive="dropdown-item-active">
  <a class="navigation-item" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon">{{ item.icon }}</mat-icon>
    <span class="name">{{ item.name }}</span>
  </a>

  <div class="dropdown" *ngIf="item.hasSubItems()">
    <vr-toolbar-navigation-dropdown-item
      class="dropdown-item-container" *ngFor="let subItem of item.subItems" [item]="subItem" [currentlyOpen]="currentlyOpen"></vr-toolbar-navigation-dropdown-item>
  </div>
</div>

<div class="navigation-item-container" *ngIf="!item.hasSubItems()">
  <a [routerLink]="item.route" routerLinkActive="active" [routerLinkActiveOptions]="item.routerLinkActiveOptions"
     class="navigation-item" fxLayout="row" fxLayoutAlign="start center" mat-ripple>
    <mat-icon class="icon">{{ item.icon }}</mat-icon>
    <span class="name">{{ item.name }}</span>
  </a>
</div>

