// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export var environment = {
  production: false,
  apiBaseUrl: "//localhost:5005/api/v1/",
  //apiBaseUrl: "https://services.practiceevolve.com.au/api/v1/",
  googleMapsApiKey: "",
  auth: {
    CLIENT_DOMAIN: "practiceevolve-dev.au.auth0.com",
    CLIENT_ID: "0BcZlWLJl8T6N1ozXtZSnmeEmIMnAGbu",
    AUDIENCE: "pe.devops.onprem",
    SCOPE: "openid email profile"
  },
};
