import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';


@Pipe({ name: 'filter' })
export class Filter implements PipeTransform {
    transform(value: any, args: string[]): any {
        let filter = args[0];
        try {
            if (filter && Array.isArray(value)) {
                let filterKeys = Object.keys(filter);
                return value.filter(item =>
                    filterKeys.reduce((memo, keyName) =>
                        memo && (item[keyName] || "").toLowerCase().indexOf((filter[keyName] || "").toLowerCase()) != -1, true));
            } else {
                return value;
            }
        }
        catch (ex) {
            return value;
        }
    }
}


@Pipe({ name: 'orderBy', pure: false })
export class OrderBy implements PipeTransform {

    static _orderByComparator(a: any, b: any): number {

        if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
            //Isn't a number so lowercase the string to properly compare
            if ((a || "").toLowerCase() < (b || "").toLowerCase()) return -1;
            if ((a || "").toLowerCase() > (b || "").toLowerCase()) return 1;
        }
        else {
            //Parse strings as numbers to compare properly
            if (parseFloat(a) < parseFloat(b)) return -1;
            if (parseFloat(a) > parseFloat(b)) return 1;
        }

        return 0; //equal each other
    }

    transform(input: any, [config = '+']): any {
        
        if (!Array.isArray(input)) return input;
        
        try {
            if (!Array.isArray(config) || (Array.isArray(config) && config.length == 1)) {
                var propertyToCheck: string = !Array.isArray(config) ? config : config[0];
                var desc = propertyToCheck.substr(0, 1) == '-';
                
                //Basic array
                if (!propertyToCheck || propertyToCheck == '-' || propertyToCheck == '+') {
                    return !desc ? input.sort() : input.sort().reverse();
                }
                else {
                    var property: string = propertyToCheck.substr(0, 1) == '+' || propertyToCheck.substr(0, 1) == '-'
                        ? propertyToCheck.substr(1)
                        : propertyToCheck;

                    return input.sort(function (a: any, b: any) {
                        return !desc ?
                            OrderBy._orderByComparator(a[property], b[property])
                            : -OrderBy._orderByComparator(a[property], b[property]);
                    });
                }
            }
            else {
                //Loop over property of the array in order and sort
                return input.sort(function (a: any, b: any) {
                    for (var i: number = 0; i < config.length; i++) {
                        var desc = config[i].substr(0, 1) == '-';
                        var property = config[i].substr(0, 1) == '+' || config[i].substr(0, 1) == '-'
                            ? config[i].substr(1)
                            : config[i];

                        var comparison = !desc ?
                            OrderBy._orderByComparator(a[property], b[property])
                            : -OrderBy._orderByComparator(a[property], b[property]);
                        
                        //Don't return 0 yet in case of needing to sort by next property
                        if (comparison != 0) return comparison;
                    }

                    return 0; //equal each other
                });
            }
        }
        catch (ex) {
            return input;
        }
    }
}

@Pipe({ name: 'formatNumber', pure: true })
export class ParseDecimalPipe extends DecimalPipe {
    transform(value: any, digits?: string): any {
        if (!value) return "-";
        let number = Number(value);
        if (isNaN(number))
            return null;
        else
            return super.transform(number, digits);
    }
}

@Pipe({ name: 'siteOrderBy', pure: false })
export class SiteOrderBy implements PipeTransform {

    static _getSortValue(property: string, site: any) {
        if (property === 'SiteName')
            return site.SiteName;
        else if (property === 'Version') {
            var i, k = "0";
            if (!(site.StatusData.Application.Version)) return 0;
            var segs = site.StatusData.Application.Version.split('.');
            for (i = 0; i < segs.length; ++i) {
                var str = segs[i];
                var pad = "0000"
                var ans = pad.substring(0, pad.length - str.length) + str
                k += ans + ".";
            }
            return k;
        }
        else if (property === 'Time') {
            return -site.EventTime;
        }
    }

    static _orderByComparator(property: string, a: any, b: any): number {
        var a = this._getSortValue(property, a);
        var b = this._getSortValue(property, b);
        if (a < b) return -1;
        else if (a > b) return 1;
        else return 0;
    }

    transform(input: any, config: any): any {
        if (!Array.isArray(input)) return input;

        if (!Array.isArray(config) || (Array.isArray(config) && config.length == 1)) {
            var propertyToCheck: string = ((typeof config) == "string") ? config : config[0];
            var desc = propertyToCheck.substr(0, 1) == '-';

            var property: string = propertyToCheck.substr(0, 1) == '+' || propertyToCheck.substr(0, 1) == '-'
                ? propertyToCheck.substr(1)
                : propertyToCheck;

            return input.sort(function (a: any, b: any) {
                return !desc ?
                    SiteOrderBy._orderByComparator(property, a, b)
                    : -SiteOrderBy._orderByComparator(property, a, b);
            });
        }

        return input;
    }
}

// Order the Timeslots by Datetime, followed up by Timeslot Type
@Pipe({ name: 'slotOrderBy', pure: false })
export class SlotOrderBy implements PipeTransform {

    static _getSortValue(property: string, slot: any) {
        if (property === 'dateTime') {
            return slot.dateTime + slot.taskType;
        }
    }

    static _orderByComparator(property: string, a: any, b: any): number {
        var a = this._getSortValue(property, a);
        var b = this._getSortValue(property, b);
        if (a < b) return -1;
        else if (a > b) return 1;
        else return 0;
    }

    transform(input: any, config: any): any {
        if (!Array.isArray(input)) return input;
        
        if (!Array.isArray(config) || (Array.isArray(config) && config.length == 1)) {
            var propertyToCheck: string = ((typeof config) == "string") ? config : config[0]; //!Array.isArray(config) ? config : config[0];
            var desc = propertyToCheck.substr(0, 1) == '-';
            
            var property: string = propertyToCheck.substr(0, 1) == '+' || propertyToCheck.substr(0, 1) == '-'
                ? propertyToCheck.substr(1)
                : propertyToCheck;
            
            return input.sort(function (a: any, b: any) {
                return !desc ?
                    SlotOrderBy._orderByComparator(property, a, b)
                    : -SlotOrderBy._orderByComparator(property, a, b);
            });
        }

        return input;
    }
}

@Pipe({ name: 'requestOrderBy', pure: false })
export class RequestOrderBy implements PipeTransform {

    static lookup = {};

    static initialize() {
        RequestOrderBy.lookup["WaitForITDetails"] = 0;
        RequestOrderBy.lookup["WaitForSysReqConfirmation"] = 1;
        RequestOrderBy.lookup["WaitForTimeSelection"] = 2;
        RequestOrderBy.lookup["WaitForFinalConfirmation"] = 3;
        RequestOrderBy.lookup["WaitForUpdateCompletion"] = 4;
        RequestOrderBy.lookup["Completed"] = 5;
        RequestOrderBy.lookup["Cancelled"] = 6;
    }

    static _getSortValue(property: string, request: any) {
        if (property === 'SiteName')
            return request.SiteName;
        else if (property === 'Version') {
            var i, k = "0";
            if (!(request.VersionNumber)) return 0;
            var segs = request.VersionNumber.split('.');
            for (i = 0; i < segs.length; ++i) {
                var str = segs[i];
                var pad = "0000"
                var ans = pad.substring(0, pad.length - str.length) + str
                k += ans + ".";
            }
            return k;
        }
        else if (property === 'SelectedTimeSlot')
            return request.SelectedTimeSlot;
        else if (property === 'State')
            return RequestOrderBy.lookup[request.CurrentState] || -1;
        else
            return request.UpdateType;
    }

    static _orderByComparator(property: string, a: any, b: any): number {
        var a = this._getSortValue(property, a);
        var b = this._getSortValue(property, b);
        if (a < b) return -1;
        else if (a > b) return 1;
        else return 0;
    }

    transform(input: any, config: any): any {
        if (!Array.isArray(input)) return input;

        if (!Array.isArray(config) || (Array.isArray(config) && config.length == 1)) {
            var propertyToCheck: string = (typeof config == "string") ? config : config[0];
            var desc = propertyToCheck.substr(0, 1) == '-';

            var property: string = propertyToCheck.substr(0, 1) == '+' || propertyToCheck.substr(0, 1) == '-'
                ? propertyToCheck.substr(1)
                : propertyToCheck;

            return input.sort(function (a: any, b: any) {
                return !desc ?
                    RequestOrderBy._orderByComparator(property, a, b)
                    : -RequestOrderBy._orderByComparator(property, a, b);
            });
        }

        return input;
    }
}
RequestOrderBy.initialize();

@NgModule({
    imports: [CommonModule],
    declarations: [Filter, OrderBy, ParseDecimalPipe, SiteOrderBy, SlotOrderBy, RequestOrderBy],
    exports: [Filter, OrderBy, ParseDecimalPipe, SiteOrderBy, SlotOrderBy, RequestOrderBy],
})
export class BasicPipeModule { }