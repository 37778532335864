import { switchMap, map, debounceTime, filter } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// import @ngrx
import { Effect, Actions, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";

// import rxjs
import { Observable, EMPTY, of } from "rxjs";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";

// import services
import { UserService } from "./user.service";
import { User } from "./user";
import { State } from "./users.reducers";

// import actions
import {
  ActionTypes,
  AuthenticatedErrorAction,
  AuthenticatedSuccessAction,
  AuthenticationErrorAction,
  AuthenticationSuccessAction,
  SignOutErrorAction,
  SignOutSuccessAction,
  SignUpErrorAction,
  SignUpSuccessAction,
  SignOutAction,
  AuthenticateAction,
} from "./users.actions";

import * as fromRoot from "../../reducers";
import { AuthService } from "../../auth.service";

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 * The `toPayload` helper function returns just
 * the payload of the currently dispatched action, useful in
 * instances where the current state is not necessary.
 *
 * Documentation on `toPayload` can be found here:
 * https://github.com/ngrx/effects/blob/master/docs/api.md#topayload
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class UserEffects {
  /**
   * Authenticate user.
   */
  @Effect()
  public authenticate: Observable<Action> = this.actions.pipe(
    ofType(ActionTypes.AUTHENTICATE),
    debounceTime(500),
    map((a) => (<any>a).payload),
    switchMap((payload) => {
      this.authService.login(window.location.pathname);
      // return this.userService.authenticate(payload.userName, payload.password)
      //     .map(user => new AuthenticationSuccessAction({ user: user }))
      //     .catch(error => Observable.of(new AuthenticationErrorAction({ error: error })));
      return EMPTY;
    })
  );

  // /**
  //  * Determine if the user is authenticated.
  //  */
  // @Effect()
  // public authenticated: Observable<Action> = this.actions
  //     .ofType(ActionTypes.AUTHENTICATED)
  //     .map(toPayload)
  //     .switchMap(payload => {
  //         return this.userService.authenticatedUser()
  //             .map(user => new AuthenticatedSuccessAction({ authenticated: (user !== null), user: user }))
  //             .catch(error => Observable.of(new AuthenticatedErrorAction({ error: error })));
  //     });

  // /**
  //  * Create a new user.
  //  */
  // @Effect()
  // public createUser: Observable<Action> = this.actions
  //     .ofType(ActionTypes.SIGN_UP)
  //     .debounceTime(500)
  //     .map(toPayload)
  //     .switchMap(payload => {
  //         return this.userService.create(payload.user)
  //             .map(user => new SignUpSuccessAction({ user: user }))
  //             .catch(error => Observable.of(new SignUpErrorAction({ error: error })));
  //     });

  /**
   * Terminate user session.
   */
  @Effect()
  public signOut: Observable<Action> = this.actions.pipe(
    ofType(ActionTypes.SIGN_OUT),
    map((a) => (<any>a).payload),
    map((payload) => {
      localStorage.removeItem("access_token");
      this.authService.logout();
      return new SignOutSuccessAction();
    })
  );

  @Effect()
  public signOutSuccess: Observable<Action> = this.actions.pipe(
    ofType(ActionTypes.SIGN_OUT_SUCCESS),
    switchMap((payload) => {
      // this.authService.login(window.location.pathname);
      return [];
    })
  );

  /**
   * Look at all actions, if it is 401, go to login page
   */
  @Effect()
  public invalidateSession: Observable<Action> = this.actions.pipe(
    map((a) => (<any>a).payload),
    filter((payload) => payload && payload.error),
    filter((payload) => payload.error.status === 401),
    switchMap((payload) => of(new AuthenticateAction(null)))
  );

  /**
   * @constructor
   * @param {Actions }actions
   * @param {UserService} userService
   */
  constructor(
    private actions: Actions,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private store: Store<State>
  ) {}
}
