import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

// import { MediaReplayService } from './utils/media-replay.service';
import { LayoutModule } from './layout/layout.module';
import { AuthenticatedGuard } from "./users/authenticated.guard";
import { UserService } from "./users/user.service";
import { User } from "./users/user";
import { AuthHttp } from "./utils/auth.http";
import { MomentPipeModule } from './utils/moment.pipe';
import { BasicPipeModule } from './utils/basic-pipes';

@NgModule({
    imports: [
        CommonModule,
        LayoutModule
    ],
    exports: [
        MomentPipeModule,
        BasicPipeModule
    ],
    providers: [
        AuthHttp,

        UserService,
        AuthenticatedGuard
    ]
})
export class CoreModule { }
