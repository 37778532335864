<div class="toolbar" fxLayout="row">

  <div fxLayout="row" fxLayoutAlign="start center" fxHide.gt-sm>
    <button type="button" mat-icon-button (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="logo-contrast" *ngIf="sidenavCollapsed"
       fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-sm>
    <img class="logo" alt="logo" src="assets/img/logo_dark2x.png">
  </div>

  <div fxLayout="row" fxLayoutAlign="end stretch" fxLayoutAlign.gt-sm="space-between stretch" fxFlex>
    <div class="search-bar" fxHide fxShow.gt-sm
         fxLayout="row" fxLayoutAlign="start center" fxFlex fxFlex.gt-md="500px">
      <!-- <vr-search-bar></vr-search-bar> -->
    </div>

    <div fxLayout="row" fxFlexOffset.gt-sm="24px">
      <vr-search fxShow fxHide.gt-sm></vr-search>

      <!-- <vr-toolbar-notifications></vr-toolbar-notifications> -->

      <vr-toolbar-user-button></vr-toolbar-user-button>

      <!-- <button mat-button class="quickpanel-button icon-button" [class.open]="quickpanelOpen" (click)="toggleQuickpanel()">
        <mat-icon class="icon">format_align_right</mat-icon>
      </button> -->
    </div>
  </div>
</div>

