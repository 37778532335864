export class SignIn {
    public userName: string;
    public password: string;
}

export class SignInResult {
    public token: string;
    public claims: Claim[];
    public success: boolean;
    public expires: Date;
    public message: string;
}

export class SignOutResult {
    public success: boolean;
    public message: string;
}

export class Claim {
    public type: string;
    public value: string;
}

export class ClaimTypes
{
    public static readonly DisplayName: string = "urn:pe.identity.display-name";
}

export class User 
{
    public displayName: string;
}