<div class="sidenav" fxLayout="column">

  <!-- Layout: Alpha -->
  <div class="header" fxLayout="row" fxLayoutAlign="start center" *ngIf="layout === 'alpha'">

    <img class="logo" alt="logo" src="assets/img/logo2x.png">
    <span fxFlex></span>

    <div class="actions" fxLayout="row" fxLayoutAlign="start center">
      <button class="toggle" type="button" mat-icon-button (click)="toggleSidenavCollapse()" *ngIf="collapsed">
        <mat-icon>radio_button_unchecked</mat-icon>
      </button>
      <button class="toggle" type="button" mat-icon-button (click)="toggleSidenavCollapse()" *ngIf="!collapsed">
        <mat-icon>radio_button_checked</mat-icon>
      </button>
    </div>
  </div>
  <!-- /Layout: Alpha -->

  <vr-scrollbar class="menu" fxFlex *ngIf="isAuthenticated$ | async">

    <div class="heading" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="name">Navigation</div>

      <!-- Layout: Beta -->
      <div class="actions" *ngIf="layout === 'beta'">
        <button class="toggle" type="button" mat-icon-button (click)="toggleSidenavCollapse()" *ngIf="collapsed">
          <mat-icon>radio_button_unchecked</mat-icon>
        </button>
        <button class="toggle" type="button" mat-icon-button (click)="toggleSidenavCollapse()" *ngIf="!collapsed">
          <mat-icon>radio_button_checked</mat-icon>
        </button>
      </div>
      <!-- /Layout: Beta -->

    </div>

    <div class="sidenav-item-holder" *ngFor="let item of sidenavItems$ | async">
      <vr-sidenav-item class="sidenav-item-container" [item]="item" [currentlyOpen]="currentlyOpen$ | async" ></vr-sidenav-item>
    </div>

  </vr-scrollbar>

</div>
