import {Pipe, ChangeDetectorRef, PipeTransform, OnDestroy} from '@angular/core';
import * as moment_ from 'moment';
const moment: (value?: any) => moment_.Moment = (<any>moment_).default || moment_;

let ZeroMoment = moment("0001-01-01T00:00:00Z");

import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';

@Pipe({ name: 'formatDate', pure: true })
export class DateFormatPipe implements PipeTransform {
    transform(value: Date | moment_.Moment, args: string): any {
        if (moment(value).isSame(ZeroMoment))
            return "-";
        else
            return moment(value).local().format(args);
    }
}

@Pipe({ name: 'timeAgo', pure: true })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
    private _currentTimer: number;

    constructor(private _cdRef: ChangeDetectorRef) {
    }

    transform(value: Date | moment_.Moment, args?: any[]): any {
        const momentInstance = moment(value);

        if (momentInstance.isSame(ZeroMoment))
            return "-";
        return momentInstance.fromNow();
        //this._removeTimer();
        //const timeToUpdate = this._getSecondsUntilUpdate(momentInstance) * 1000;
        //this._currentTimer = window.setTimeout(() => this._cdRef.markForCheck(), timeToUpdate);
        //var now = moment();
        //var subject = moment(value);
        //if (now.isBefore(subject)) now = subject;
        //return subject.from(now);
    }

    ngOnDestroy(): void {
        this._removeTimer();
    }

    _removeTimer() {
        if (this._currentTimer) {
            window.clearTimeout(this._currentTimer);
            this._currentTimer = null;
        }
    }

    _getSecondsUntilUpdate(momentInstance: moment_.Moment) {
        const howOld = Math.abs(moment().diff(momentInstance, 'minute'));
        if (howOld < 1) {
            return 1;
        } else if (howOld < 60) {
            return 30;
        } else if (howOld < 180) {
            return 300;
        } else {
            return 3600;
        }
    }
}

@NgModule({
    imports: [CommonModule],
    declarations: [TimeAgoPipe, DateFormatPipe],
    exports: [TimeAgoPipe, DateFormatPipe],
})
export class MomentPipeModule { }