import { throwError as observableThrowError, Observable, NEVER } from "rxjs";

import { catchError, map, shareReplay, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../auth.service";

export class Unauthorized {
  constructor(public message: string) {}
}

const LOCAL_STORE_ACCESS_TOKEN = "access_token";
@Injectable()
export class AuthHttp {
  private _http$: Observable<{
    http: HttpClient;
    options: { headers: HttpHeaders };
  }>;

  constructor(http: HttpClient, private authService: AuthService) {
    this._http$ = this.authService.accessToken$.pipe(
      map((token) => {
        let headers = new HttpHeaders().set("Content-Type", "application/json");
        if (token) headers = headers.set("Authorization", `Bearer ${token}`);
        let options = { headers: headers };
        return { http, options };
      }),
      shareReplay(1)
    );
  }

  public put(url: string, data: any): Observable<Object> {
    return this._http$.pipe(
      switchMap(({ http, options }) => http.put(url, data, options)),
      map((data) => data),
      catchError((response, caught) => this.handle(response, caught))
    );
  }

  public post(url: string, data: any): Observable<Object> {
    return this._http$.pipe(
      switchMap(({ http, options }) => http.post(url, data, options)),
      map((data) => data),
      catchError((response, caught) => this.handle(response, caught))
    );
  }

  public delete(url: string): Observable<Object> {
    return this._http$.pipe(
      switchMap(({ http, options }) => http.delete(url, options)),
      map((data) => data),
      catchError((response, caught) => this.handle(response, caught))
    );
  }

  public get(url: string): Observable<Object> {
    return this._http$.pipe(
      switchMap(({ http, options }) => http.get(url, options)),
      map((data) => data),
      catchError((response, caught) => this.handle(response, caught))
    );
  }

  private handle(response, caught) {
    if (response.status == 401) {
      this.authService.login(window.location.pathname);
      return NEVER;
    } else if (response.status != 0) return observableThrowError(caught);
    else
      return observableThrowError({
        status: 0,
        message: "Cannot connect to server",
      });
  }
}
