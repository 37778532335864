
import {filter, takeWhile} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";

// rxjs
import { Observable } from "rxjs";
import "rxjs/add/operator/takeWhile";

// actions
import { AuthenticateAction, SignOutAction } from "../../../core/users/users.actions";

// reducers
import {
    getAuthenticationError,
    isAuthenticated,
    isAuthenticationLoading,
    State
} from "../../../reducers";

@Component({
    selector: 'vr-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [...ROUTE_TRANSITION],
    host: { '[@routeTransition]': '' }
})
export class LoginComponent implements OnInit {

    /** Form bindings */
    public username: string;
    public password: string;

    /**
     * The error if authentication fails.
     * @type {Observable<string>}
     */
    public error$: Observable<string>;

    /**
     * True if the authentication is loading.
     * @type {boolean}
     */
    public loading$: Observable<boolean>;

    /**
     * Component state.
     * @type {boolean}
     */
    private alive = true;


    constructor(
        private router: Router,
        private store: Store<State>
    ) { }

    ngOnInit() {

        // set error
        this.error$ = this.store.select(getAuthenticationError);

        // set loading
        this.loading$ = this.store.select(isAuthenticationLoading);

        // subscribe to success
        this.store.select(isAuthenticated).pipe(
            takeWhile(() => this.alive),
            filter(authenticated => authenticated),)
            .subscribe(value => {
                this.router.navigate(["/"]);
            });
    }

    /**
     *  Lifecycle hook that is called when a directive, pipe or service is destroyed.
     * @method ngOnDestroy
     */
    public ngOnDestroy() {
        this.alive = false;
    }

    login() {
        // trim values
        this.username.trim();
        this.password.trim();
        
        // set payload
        const payload = {
            userName: this.username,
            password: this.password
        };
        
        // dispatch AuthenticationAction and pass in payload
        this.store.dispatch(new AuthenticateAction(payload));
    }
}
