
import {filter, map, switchMap, tap} from 'rxjs/operators';
import { Injectable } from "@angular/core";

// import @ngrx
import { Effect, Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { EMPTY, Observable, of } from "rxjs";

import { ShowNotificationAction } from "./layout.action";

@Injectable()
export class LayoutEffects {

    /**
     * Look at all actions, if it is 401, go to login page
     */
    @Effect()
    public showGenericError: Observable<Action> = this._actions.pipe(
        map(a => (<any>a).payload),
        filter(payload => payload && payload.error),
        tap(payload => console.log(payload.error)),
        filter(payload => payload.error.status !== 401),
        switchMap(payload => {
            var error = payload.error;
            if (!error) EMPTY;
            return of(new ShowNotificationAction(error.message));
        }));


    constructor(
        private _actions: Actions)
    { }
}